<template>
  <div class="about-company" id="about">
    <h2>О компании</h2>
    <div class="about-company__content">
      <p>
        Компания <b>“ДЛК Строй”</b> занимается отделкой коммерческих помещений <b>с 2016 года</b>. <br/> <br/>
        Наша компания специализируется на отделочных работах <b>под ключ</b>.
        Для вас мы готовы предложить полноценный спектр услуг.
        Мастера с опытом работы <b>более 15 лет</b> качественно и в срок выполнят ваш заказ,
        чтобы вы были довольны не только результатом, но и процессом ремонта.
      </p>
      <div class="about-company__content__image"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AboutCompany"
}
</script>