<template>
  <div class="page-grid">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageGrid',
  props: {
    isWide: {
      type: Boolean,
      default: false,
    }
  }
}
</script>