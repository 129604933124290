<template>
  <div class="profits">
    <div v-for="(element, index) in profits" :key="'profits-item-' + index" class="profits__item">
      <div class="profits__item__icon">
        <img :src="'./icons/' + element.icon + '.svg'" :alt="element.icon + ' logo'"/>
      </div>
      <p v-html="element.text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfitSection",
  data() {
    return {
      profits: [
        {
          icon: 'finishing',
          text: 'Отделка помещения <br/><b>под ключ</b>',
        },
        {
          icon: 'age-15',
          text: 'Опыт работы <br/><b>более 15 лет</b>',
        },
        {
          icon: 'contract',
          text: 'Работаем в срок <br/><b>по договору</b>',
        },
        {
          icon: 'location',
          text: 'Работаем в г. Екатеринбург <br/><b>+1000 км</b>',
        }
      ]
    }
  }
}
</script>