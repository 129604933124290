<template>
  <div class="what-we-do" id="what-we-do">
    <PageGrid>
      <h2>Что мы делаем?</h2>
    </PageGrid>
    <SliderImage
        v-if="isTablet === false"
        class="slider-component"
        :options="laptopOptions"
        :images="items"
    />
    <SliderImage
        v-if="isTablet === true"
        class="slider-component"
        :options="tabletOptions"
        :images="items"
    />
  </div>
</template>

<script>
import SliderImage from "@/components/common/slider-image";
import PageGrid from "@/components/grid"

export default {
  name: "WhatWeDo",
  components: {
    SliderImage,
    PageGrid
  },
  props: {
    isTablet: {
      type: Boolean,
    }
  },

  data() {
    return {
      items: [
        {
          src: 'what-we-do/what-we-do-2.png',
          alt: 'theater decoration',
          text: 'Отделка театров',
        },
        {
          src: 'what-we-do/what-we-do-3.png',
          alt: 'office decoration',
          text: 'Отделка офисов',
        },
        {
          src: 'what-we-do/what-we-do-1.png',
          alt: 'shop decoration',
          text: 'Отделка магазинов',
        },
      ],
      tabletOptions: {
        autoPlay: false,
        wrapAround: false,
        prevNextButtons: false,
        pageDots: false,
        contain: true,
      },
      laptopOptions: {
        freeScroll: false,
        draggable: false,
        autoPlay: true,
        wrapAround: true,
      }
    }
  },
}
</script>