<template>
  <div class="our-works" id="works">
    <h2>Наши работы</h2>
    <h3>Убедитесь в <b>качестве</b> нашего ремонта на примерах</h3>
    <div class="our-works__list">
      <div
          v-for="(element, index) in items"
          :key="'our-works-item-' + index"
          class="our-works__list__item"
      >
        <PageGrid v-if="isTablet === false">
          <div class="our-works__list__item__gallery">
            <img
                v-for="(item, key) in element.images"
                :src="'/images/our-works/' + item.src"
                :alt="item.alt"
                :key="'our-works-gallery-item-' + index + '-' + key"
                :class="item?.main ? 'main' : 'two-rows'"
            />
          </div>
        </PageGrid>
        <SliderCustom
            v-if="isTablet === true"
            :options="options"
        >
          <div
              v-for="(item, key) in element.images"
              :key="'our-works-gallery-item-' + index + '-' + key"
              class="slider-image"
          >
            <img
                :src="'/images/our-works/' + item.src"
                :alt="item.alt"
            />
          </div>
        </SliderCustom>
        <PageGrid>
          <div class="our-works__list__item__info">
          <span>
            <img src="/icons/location-mini.svg" alt="location icon">
            {{ element.info }}
          </span>
            <span class="our-works__list__item__info__city">
            {{ element.city }}
          </span>
          </div>
        </PageGrid>
        <SpacerBlock/>
      </div>
    </div>
  </div>
</template>

<script>
import SpacerBlock from "@/components/common/spacer";
import PageGrid from "@/components/grid";
import SliderCustom from "@/components/common/slider-custom";

export default {
  name: "OurWorks",
  components: {
    SliderCustom,
    PageGrid,
    SpacerBlock,
  },
  props: {
    isTablet: {
      type: Boolean,
    }
  },

  data() {
    return {
      options: {
        autoPlay: false,
        prevNextButtons: false,
        contain: true,
      },
      items: [
        {
          info: 'Отделка помещения для магазина сети “Галамарт”',
          city: 'г. Воронеж',
          images: [
            {
              src: 'galamart/galamart-main.png',
              alt: 'Галамарт',
              main: true,
            },
            {
              src: 'galamart/galamart-secondary-1.png',
              alt: 'Галамарт'
            },
            {
              src: 'galamart/galamart-secondary-2.png',
              alt: 'Галамарт'
            },
          ],
        },
        {
          info: 'Отделка помещения для магазина сети “BY”',
          city: 'г. Воронеж',
          images: [
            {
              src: 'by/by-main.png',
              alt: 'BY',
              main: true,
            },
            {
              src: 'by/by-secondary-1.png',
              alt: 'BY'
            },
            {
              src: 'by/by-secondary-2.png',
              alt: 'BY'
            },
          ],
        },
        {
          info: 'Отделка магазина сети “Жизньмарт”',
          city: 'г. Тюмень, Улица Сергея Джанбровского, 5',
          images: [
            {
              src: 'lifemart/lifemart-main.jpg',
              alt: 'Lifemart-main',
              main: true,
            },
            {
              src: 'lifemart/lifemart-floor.jpeg',
              alt: 'Lifemart-floor'
            },
            {
              src: 'lifemart/lifemart-roof.jpg',
              alt: 'Lifemart-roof'
            },
          ],
        },
      ]
    }
  }
}
</script>
