<template>
  <footer class="footer" id="contacts">
    <PageGrid>
      <div class="footer__container">
        <div class="footer__container__logo-container">
          <img src="/logo.svg" alt="ДЛК СТРОЙ logo"/>
        </div>
        <div class="footer__container__links-container">
          <ul class="footer__container__links-container__list">
            <li
                class="footer__container__links-container__list__item"
                v-for="(element, index) in anchors"
                :key="'footer-link-' + index"
            >
              <a :href="'#' + element.href">{{ element.title }}</a>
            </li>
          </ul>
        </div>
        <div class="footer__container__contacts-container">
          <a class="footer__container__contacts-container__phone" :href="`tel:${contacts.phone}`">
            {{ contacts.phone }}
          </a>
          <a :href="`https://wa.me/${contacts.whatsapp}`">
            WhatsApp
          </a>
          <a :href="`mailto:${contacts.email}`">
            {{ contacts.email }}
          </a>
          <p>
            {{ contacts.location }}
          </p>
        </div>
      </div>
    </PageGrid>
  </footer>
</template>

<script>
import PageGrid from '../../grid'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  components: {
    PageGrid,
  },
  props: {
    anchors: {
      type: Array,
      default: () => [],
      required: true,
    }
  },

  data() {
    return {
      contacts: {
        phone: '+7 (961) 770 08 90',
        email: 'mas_82@mail.ru',
        whatsapp: '89617700890',
        location: 'г. Екатеринбург'
      },
    }
  }
}
</script>