<template>
  <NavBar
    @toggle="menuHandler"
    @closeMenu="closeMenu"
    :menuState="menu"
    :anchors="anchors"
  />
  <FirstScreen/>
  <SpacerBlock/>
  <PageGrid>
    <ProfitSection/>
    <SpacerBlock/>
    <AboutCompany/>
  </PageGrid>
  <SpacerBlock/>
  <WhatWeDo
    :isTablet="isTablet"
  />
  <SpacerBlock/>
  <StagesSection
      :isLaptop="isLaptop"
  />
  <PageGrid>
    <SpacerBlock/>
    <OurServices/>
  </PageGrid>
  <SpacerBlock/>
  <OurWorks
      :isTablet="isTablet"
  />
  <SpacerBlock/>
  <Footer
    :anchors="anchors"
  />
</template>

<script>
import FirstScreen from "@/components/first-screen";
import ProfitSection from "@/components/profits";
import SpacerBlock from "@/components/common/spacer";
import PageGrid from "@/components/grid";
import AboutCompany from "@/components/about";
import WhatWeDo from "@/components/what-we-do";
import StagesSection from "@/components/stages";
import OurServices from "@/components/our-services";
import OurWorks from "@/components/our-works";
import NavBar from "@/components/common/navbar";
import Footer from "@/components/common/footer";

export default {
  name: 'App',
  components: {
    Footer,
    NavBar,
    OurWorks,
    OurServices,
    StagesSection,
    WhatWeDo,
    AboutCompany,
    PageGrid,
    SpacerBlock,
    ProfitSection,
    FirstScreen
  },

  data() {
    return {
      isTablet: true,
      isLaptop: true,
      menu: false,
      anchors: [
        {
          title: 'О компании',
          href: 'about',
        },
        {
          title: 'Что мы делаем?',
          href: 'what-we-do',
        },
        {
          title: 'Этапы работы',
          href: 'stages',
        },
        {
          title: 'Наши услуги',
          href: 'services',
        },
        {
          title: 'Наши работы',
          href: 'works',
        },
        {
          title: 'Контакты',
          href: 'contacts',
        },
      ]
    }
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.setBreakpoints(window.innerWidth)
    })

    this.setBreakpoints(window.innerWidth)
  },

  methods: {
    menuHandler() {
      this.menu = !this.menu
    },

    closeMenu() {
      this.menu = false
    },

    setBreakpoints(innerWidth) {
      this.isTablet = innerWidth <= 768
      this.isLaptop = innerWidth <= 1280
    }
  }
}
</script>