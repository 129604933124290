<template>
  <menu class="menu">
    <div class="menu__logo">
      <img src="/logo.svg" alt="ДЛК СТРОЙ logo"/>
    </div>
    <div class="menu__contacts">
      <a :href="'tel:' + phone">{{phone}}</a>
      <span>Мы принимаем звонки и&nbsp;сообщения в WhatsApp</span>
    </div>
    <div class="menu__links">
      <a
          v-for="(element, index) in anchors"
          class="menu__links__link"
          :href="'#' + element.href"
          :key="'menu-item-' + index"
          @click="$emit('selected')"
      >
        {{element.title}}
      </a>
    </div>
  </menu>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Menu",
  props: {
    anchors: {
      type: Array,
      default: () => [],
      required: true,
    }
  },

  data() {
    return {
      phone: '+7 (961) 770 08 90',
    }
  }
}
</script>

<style scoped>

</style>