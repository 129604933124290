<template>
  <div class="first-screen">
    <PageGrid>
      <div class="first-screen__content">
        <h1>Отделка коммерческих помещений</h1>
        <div class="first-screen__profits">
        <span
            v-for="(element, index) in profits"
            :key="'profits-item-' + index"
            class="first-screen__profits__item"
        >
          {{ element }}
        </span>
        </div>
        <ButtonAccent content="Связаться" :link="`https://wa.me/${contacts.whatsapp}`"/>
      </div>
    </PageGrid>
  </div>
</template>

<script>
import './index.scss';
import ButtonAccent from "@/components/common/button";
import PageGrid from "@/components/grid";

export default {
  name: "FirstScreen",
  components: {PageGrid, ButtonAccent},

  data() {
    return {
      profits: [
        'Быстро',
        'Качественно',
        'По доступной цене',
      ],

      contacts: {
        phone: '+7 (961) 770 08 90',
        email: 'mas_82@mail.ru',
        whatsapp: '89617700890',
        location: 'г. Екатеринбург'
      }
    }
  }
}
</script>
