<template>
  <div class="stages" id="stages">
    <PageGrid>
      <h2>Этапы работ</h2>
      <div v-if="isLaptop === false" class="stages__items">
        <div
            v-for="(element, index) in items"
            :key="'stage-item-' + index"
            class="stages__item"
        >
          <div class="stages__item__content">
            <img
                :src="'/images/stages/' + element.icon"
                :alt="element.alt"
            >
            <p>{{ element.text }}</p>
          </div>
        </div>
      </div>
      <div v-if="isLaptop === true">
        <SliderCustom :options="sliderOptions">
          <div
              v-for="(element, index) in items"
              :key="'stage-item-' + index"
              class="stages__item"
          >
            <div class="stages__item__content">
              <img
                  :src="'/images/stages/' + element.icon"
                  :alt="element.alt"
              >
              <p>{{ element.text }}</p>
            </div>
          </div>
        </SliderCustom>
      </div>
    </PageGrid>
  </div>
</template>

<script>
import SliderCustom from "@/components/common/slider-custom";
import PageGrid from "@/components/grid";

export default {
  name: "StagesSection",
  components: {
    SliderCustom,
    PageGrid,
  },
  props: {
    isLaptop: {
      type: Boolean,
    }
  },

  data() {
    return {
      sliderOptions: {
        freeScroll: true,
        contain: true,
        prevNextButtons: false,
        pageDots: false,
      },
      items: [
        {
          icon: 'phone.svg',
          alt: 'phone icon',
          text: 'Уточнение информации по телефону',
        },
        {
          icon: 'ruler.svg',
          alt: 'ruler icon',
          text: 'Выезд замерщика на объект',
        },
        {
          icon: 'calculator.svg',
          alt: 'calculator icon',
          text: 'Калькуляция проекта',
        },
        {
          icon: 'document.svg',
          alt: 'document icon',
          text: 'Подписание договора',
        },
        {
          icon: 'roller.svg',
          alt: 'roller icon',
          text: 'Отделочные работы',
        },
      ]
    }
  },
}
</script>