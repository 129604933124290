<template>
  <div
      class="navbar"
      :class="{
        'with-bg' : pageYOffsetCheck(),
        'init' : init,
      }"
  >
    <PageGrid>
      <div class="navbar__content">
        <div class="navbar__content__logo">
          <a href="#">
            <img src="/logo.svg" alt="ДЛК СТРОЙ logo"/>
          </a>
        </div>
        <div class="navbar__content__items">
          <a :href="'tel:' + contacts.phone">
            {{ contacts.phone }}
          </a>
          <ButtonAccent
              modify="outlined"
              content="WhatsApp"
              :link="'https://wa.me/' + contacts.whatsapp"
          />
          <p class="navbar__content__items__location">{{ contacts.location }}</p>
        </div>
        <div
            class="navbar__content__burger-container"
            @click="$emit('toggle')"
        >
          <div class="navbar__content__burger-container__burger-button">
            <div
                class="menu-toggle"
                :class="{
                active: menuState,
              }"
            >
              <div class='hamburger CTarget'>
                <span class='CTarget'></span>
                <span class='CTarget'></span>
                <span class='CTarget'></span>
              </div>
              <div class='cross CTarget'>
                <span class='CTarget'></span>
                <span class='CTarget'></span>
              </div>
            </div>
          </div>
        </div>
        <Menu
            :anchors="anchors"
            :class="{active : menuState}"
            @selected="$emit('closeMenu')"
        />
      </div>
    </PageGrid>
  </div>
</template>

<script>
import ButtonAccent from "@/components/common/button";
import Menu from "@/components/common/menu";
import PageGrid from "@/components/grid";

export default {
  name: "NavBar",
  components: {
    PageGrid,
    ButtonAccent,
    Menu
  },
  props: {
    menuState: {
      type: Boolean,
    },
    anchors: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      init: true,
      pageY: 300,
      contacts: {
        phone: '+7 (961) 770 08 90',
        whatsapp: '89617700890',
        location: 'г. Екатеринбург'
      },
    }
  },

  mounted() {
    this.init = false
    this.pageY = window.pageYOffset

    window.addEventListener('scroll', () => {
      this.pageY = window.pageYOffset
    })
  },

  methods: {
    pageYOffsetCheck() {
      return this.pageY > 100
    }
  }
}
</script>