<template>
  <div class="our-services" id="services">
    <h2>Наши услуги</h2>
    <div class="our-services__list">
      <div
          v-for="(element, index) in items"
          :key="'service-item-' + index"
          class="our-services__list__item"
      >
        <p v-html="element.text"></p>
        <img
            :src="'/images/services/' + element.src"
            :alt="element.text"
        />
        <span class="our-services__list__item--index">
          {{'0' + (index + 1)}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
  data() {
    return {
      items: [
        {
          src: 'materials.png',
          text: 'Выбор только качественных <b>материалов</b>',
        },
        {
          src: 'prepare.png',
          text: '<b>Подготовка</b> всех поверхностей к нанесению финишных материалов',
        },
        {
          src: 'decoration.png',
          text: '<b>Отделка стен</b> штукатуркой',
        },
        {
          src: 'glazing.png',
          text: '<b>Остекление</b> и облицовочные работы ',
        },
        {
          src: 'painting.png',
          text: '<b>Окрашивание</b> поверхностей',
        },
        {
          src: 'roll.png',
          text: 'Покрытие различными <b>рулонными материалами</b>',
        },
        {
          src: 'facade.png',
          text: 'Разные варианты <b>фасадных работ</b>',
        },
        {
          src: 'finishing.png',
          text: '<b>Финишная</b> отделка',
        },
      ]
    }
  }
}
</script>