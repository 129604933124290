<template>
  <button
      v-if="!link"
      class="button-accent"
      :class="modify"
  >
    {{content}}
  </button>
  <a
      v-if="link"
      class="button-accent"
      :href="link"
      :class="modify"
  >
    {{content}}
  </a>
</template>

<script>
export default {
  name: 'ButtonAccent',
  props: {
    content: {
      type: String,
      default: '',
      required: true,
    },
    modify: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    }
  }
}
</script>